var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{staticStyle:{"width":"2rem","height":"2rem"},attrs:{"src":"/logo-new.png","alt":"Logo"}}),_c('h2',{staticClass:"brand-text text-success ml-1"},[_vm._v(" Habit ")])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"4"}},[_c('div',{staticClass:"w-100 d-lg-flex flex-column align-items-center justify-content-center px-5"},[_c('img',{staticStyle:{"width":"10rem","height":"10rem"},attrs:{"src":"/logo-new.png","alt":"Logo"}}),_c('h6',{staticClass:"mt-4 text-success text-center",staticStyle:{"line-height":"1.5"}},[_vm._v(" "+_vm._s(_vm.$t('message.msgSlogan1'))),_c('br'),_vm._v(_vm._s(_vm.$t('message.msgSlogan2'))+" ")])])]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"8"}},[(_vm.isLoading)?_c('loading',{attrs:{"active":true,"is-full-page":false,"color":_vm.colors.primary}}):_vm._e(),_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[(!_vm.isLoading && !_vm.messageSent)?_c('div',[_c('b-card-title',{staticClass:"mb-1 font-weight-bold text-primary",attrs:{"title-tag":"h3"}},[_vm._v(" "+_vm._s(_vm.$t('message.contactUs'))+" ✉️ ")]),_c('span',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('message.msgContactUs'))+" ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.sendContactEmail.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":((_vm.$t('label.Name')) + " *"),"label-for":"contact-name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('label.name'),"vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contact-name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('placeholder.userDemoName')},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('label.phone'),"label-for":"contact-phone"}},[_c('b-form-input',{attrs:{"id":"contact-phone","type":"number","placeholder":_vm.$t('placeholder.phone')},model:{value:(_vm.userData.phone),callback:function ($$v) {_vm.$set(_vm.userData, "phone", $$v)},expression:"userData.phone"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('message.howDidYouMeetUs'),"label-for":"contact-meetUs"}},[_c('b-form-input',{attrs:{"id":"contact-meetUs","placeholder":"..."},model:{value:(_vm.userData.meetUs),callback:function ($$v) {_vm.$set(_vm.userData, "meetUs", $$v)},expression:"userData.meetUs"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":((_vm.$t('message.Email')) + " *"),"label-for":"contact-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Email'),"vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":((_vm.$t('label.company')) + " *"),"label-for":"contact-company"}},[_c('validation-provider',{attrs:{"name":_vm.$t('label.company'),"vid":"company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contact-company","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('placeholder.company')},model:{value:(_vm.userData.company),callback:function ($$v) {_vm.$set(_vm.userData, "company", $$v)},expression:"userData.company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('message.topicOrServiceInterested'),"label-for":"contact-topic"}},[_c('b-form-input',{attrs:{"id":"contact-topic","placeholder":"..."},model:{value:(_vm.userData.topic),callback:function ($$v) {_vm.$set(_vm.userData, "topic", $$v)},expression:"userData.topic"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":((_vm.$t('label.message')) + " *"),"label-for":"contact-message"}},[_c('validation-provider',{attrs:{"name":_vm.$t('label.message'),"vid":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"contact-message","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('placeholder.message')},model:{value:(_vm.userData.message),callback:function ($$v) {_vm.$set(_vm.userData, "message", $$v)},expression:"userData.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"mt-2",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('message.submit'))+" ")])],1)]}}],null,false,3669927307)})],1):_vm._e(),(_vm.messageSent)?_c('div',[_c('b-card-title',{staticClass:"mb-1 font-weight-bold text-primary",attrs:{"title-tag":"h3"}},[_vm._v(" "+_vm._s(_vm.$t('message.weReceivedYourMsg'))+" ✉️ ")]),_c('span',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('message.weWillContactYou'))+" ")])],1):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }