<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <img src="/logo-new.png" alt="Logo" style="width: 2rem; height: 2rem;">
        <h2 class="brand-text text-success ml-1">
          Habit
        </h2>
      </b-link>

      <!-- Left Text-->
      <b-col
        lg="4"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex flex-column align-items-center justify-content-center px-5">
          <!-- <vuexy-logo :size="200"/> -->
          <img src="/logo-new.png" alt="Logo" style="width: 10rem; height: 10rem;">
          <h6 class="mt-4 text-success text-center" style="line-height: 1.5;">
             {{ $t('message.msgSlogan1') }}<br>{{ $t('message.msgSlogan2') }}
          </h6>
        </div>
      </b-col>

      <!-- Contact-->
      <b-col
        lg="8"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <loading
          v-if="isLoading"
          :active="true" 
          :is-full-page="false"
          :color="colors.primary"
        />
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div v-if="!isLoading && !messageSent">
            <b-card-title
              class="mb-1 font-weight-bold text-primary"
              title-tag="h3"
            >
              {{ $t('message.contactUs') }} ✉️
            </b-card-title>

            <span class="mb-2">
              {{ $t('message.msgContactUs') }}
            </span>

            <!-- Form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="sendContactEmail"
              >
                <b-row>
                  <b-col cols="12" md="6">
                    <!-- Name -->
                    <b-form-group
                      :label="`${$t('label.Name')} *`"
                      label-for="contact-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('label.name')"
                        vid="name"
                        rules="required"
                      >
                        <b-form-input
                          id="contact-name"
                          v-model="userData.name"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('placeholder.userDemoName')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Phone Number -->
                    <b-form-group
                      :label="$t('label.phone')"
                      label-for="contact-phone"
                    >
                      <b-form-input
                        id="contact-phone"
                        type="number"
                        v-model="userData.phone"
                        :placeholder="$t('placeholder.phone')"
                      />
                    </b-form-group>

                    <!-- How did you meet us -->
                    <b-form-group 
                      :label="$t('message.howDidYouMeetUs')"
                      label-for="contact-meetUs"
                    >
                      <b-form-input
                        id="contact-meetUs"
                        v-model="userData.meetUs"
                        placeholder="..."
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <!-- Email -->
                    <b-form-group
                      :label="`${$t('message.Email')} *`"
                      label-for="contact-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('message.Email')"
                        vid="email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="login-email"
                          v-model="userData.email"
                          :state="errors.length > 0 ? false : null"
                          placeholder="john@example.com"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Company -->
                    <b-form-group
                      :label="`${$t('label.company')} *`"
                      label-for="contact-company"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('label.company')"
                        vid="company"
                        rules="required"
                      >
                        <b-form-input
                          id="contact-company"
                          v-model="userData.company"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('placeholder.company')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Topic of interest -->
                    <b-form-group 
                      :label="$t('message.topicOrServiceInterested')"
                      label-for="contact-topic"
                    >
                      <b-form-input
                        id="contact-topic"
                        v-model="userData.topic"
                        placeholder="..."
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Message -->
                <b-form-group
                  :label="`${$t('label.message')} *`"
                  label-for="contact-message"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('label.message')"
                    vid="message"
                    rules="required"
                  >
                    <b-form-textarea
                      id="contact-message"
                      v-model="userData.message"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('placeholder.message')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  class="mt-2"
                  block
                  :disabled="invalid"
                >
                  {{ $t('message.submit') }}
                </b-button>
              </b-form>
            </validation-observer>
          </div>
          <div v-if="messageSent">
            <b-card-title
              class="mb-1 font-weight-bold text-primary"
              title-tag="h3"
            >
              {{ $t('message.weReceivedYourMsg') }} ✉️
            </b-card-title>

            <span class="mb-2">
              {{ $t('message.weWillContactYou') }}
            </span>
          </div>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip } from 'bootstrap-vue'
import { required, email } from '@validations'
import i18n from '@/libs/i18n'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { colors } from '@/constants'
import { ref, watch, onMounted } from '@vue/composition-api'
import awsConnection from '@/views/habit/aws'
import useNotifications from '@/composables/useNotifications'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Loading
  },
  setup() {
    const userData = ref({
      name: '',
      email: '',
      phone: '',
      company: '',
      message: '',
      meetUs: '',
      topic: ''
    })
    const isLoading = ref(false)
    const messageSent = ref(false)
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { getEmailDemoHabitTemplate, getEmailDemoUserTemplate } = useCommonTodo()
    const { sendEmail } = awsConnection()

    const sendContactEmail = () => {
      isLoading.value = true

      const bodyHabit = getEmailDemoHabitTemplate(userData.value)
      const bodyUser = getEmailDemoUserTemplate(userData.value.name)

      const emailPromises = [
        sendEmail(['scox@addval.cl'], 'Contacto Demo', bodyHabit)
      ]

      if (userData.value.email) {
        emailPromises.push(sendEmail([userData.value.email], i18n.t('message.weReceivedYourMessage'), bodyUser))
      }
      
      Promise.all(emailPromises)
        .then((responses) => {
          const allMessagesSent = responses.every(response => response.MessageId)
          if (allMessagesSent) {
            showSuccessMessage(i18n.t('message.successMessage'))
            messageSent.value = true
          }
        })
        .catch((err) => {
          console.log(err)
          showErrorMessage(i18n.t('message.errorMessage'))
        })
        .finally(() => {
          isLoading.value = false
        })
    }


    onMounted( () => {
      // Define locale based on the language of the browser UI
      if (window.navigator.language?.startsWith('es')) i18n.locale = 'es'
    })
    
    return {
      colors,
      required,
      email,
      userData,
      isLoading,
      sendContactEmail,
      messageSent,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>